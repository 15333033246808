import { getRoute } from "~/lib/routes";

export default defineNuxtRouteMiddleware(async () => {
	const nuxtApp = useNuxtApp();
	if (process.client && nuxtApp.isHydrating && nuxtApp.payload.serverRendered) return;

	const {cartItems, refreshCart} = useCart()

	await refreshCart();

	if(cartItems.value.length < 1) {
		return navigateTo(getRoute("assortment"))
	}

});
